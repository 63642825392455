import React, {Component} from 'react';
import basic from "../../image/product/basic.jpg";
import intermediate from "../../image/product/intermediate.jpg";
import advanced from "../../image/product/advanced.jpg";
import iot_basic from "../../image/product/iot_basic.png";
import iot_intermediate from "../../image/product/iot_intermediate.png";
import iot_advanced from "../../image/product/iot_advanced.png";
import ai from "../../image/product/ai.png";
import ProductList from "./ProductList";
import withRouter from "../../hooks/withRouter";
//import ProductFilter from "./ProductFilter";

class Product extends Component{
    render(){
        let cat = this.props.params?.cat;
        const productData= [
            {
                id:1001,image: basic, title: "SS Basic Robotics Kit",price: "6500",category:"robotics",
            },{
                id:1002,image: intermediate, title: "SS Intermediate Robotics Kit",price: "9000",category:"robotics",
            },{
                id:1003,image: advanced, title: "SS Advanced Robotics Kit",price: "11000",category:"robotics",
            },{
                id:2001,image: iot_basic, title: "SS Basic IOT Kit",price: "15000",category:"iot",
            },{
                id:2002,image: iot_intermediate, title: "SS Intermediate IOT Kit",price: "17500",category:"iot",
            },/*{
                id:2003,image: iot_advanced, title: "SS Advanced IOT Kit",price: "21000",category:"iot",
            },*/{
                id:3001,image: ai, title: "SS AI Kit",price: "41000",category:"ai",
             }];
        return(
            <section className="product_list_area sec_pad">
                <div className="container">
                    <div className="row">
                       {/* <div className="col-md-3">
                            <ProductFilter />
                        </div>*/}
                        <div className="col-md-12">
                            <div className="row product-list">
                                {
                                    productData.map((productContent, index) => {
                                        if(productContent.category == cat || !cat) {
                                            return (
                                                <div className="col-md-4">
                                                    <ProductList key={index} item={productContent}/>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(Product);
