import React, { Component } from "react";
import './App.css';

import { BrowserRouter, Route,Routes } from "react-router-dom";
/*------ Pages-----*/
import  {Home} from "./Pages/Home";
import Contacts from "./Pages/Contacts";
import CommingSoon from "./Pages/CommingSoon";
import ErrorPage from "./Pages/ErrorPage";
import About from "./Pages/About";
import Offering from "./Pages/Offering";
import Portfolio from "./Pages/Portfolio";
import TermAndCond from "./Pages/TermAndCond";
import PrivacyPolicies from "./Pages/PrivacyPolicies";
import Products from "./Pages/Products";
import ProductDetails from "./components/product/ProductDetails";
/*------ Pages-----*/

class App extends Component {
    componentDidMount() {
        this.props.hideLoader();
    }
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />}/>
                    <Route path="/home" element={<Home />}/>
                    <Route path="/contact" element={<Contacts />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/our-work" element={<Portfolio />}/>
                    <Route path="/term-and-condition" element={<TermAndCond />}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicies />}/>
                    <Route path="/products" element={<Products />}/>
                    <Route path="/products/:cat" element={<Products />}/>
                    <Route path="/product/:id" element={<CommingSoon />}/>
                    <Route path="/our-offering" element={<Offering />}/>
                    <Route path="/our-offering/:cat" element={<Offering />}/>
                    <Route path="/*" element={<ErrorPage />}/>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
